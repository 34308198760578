import axiosIns from "@/libs/axios";

const getReporteVentasGenerales = async ({ sedeId, desde, hasta }) => {
  return await axiosIns.get(`reporte-ventas/ventas-generales?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}`)
}

const getReporteVentasGeneralesPdf = async ({ sedeId, desde, hasta }) => {
  return await axiosIns.get(`reporte-ventas/ventas-generales-pdf?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}&tipo_respuesta=base64`)
}

const getReporteVentasDetalladas = async ({ sedeId, desde, hasta }) => {
  return await axiosIns.get(`reporte-ventas/ventas-detalladas?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}`)
}

const getReporteVentasDetalladasPdf = async ({ sedeId, desde, hasta }) => {
  return await axiosIns.get(`reporte-ventas/ventas-detalladas-pdf?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}&tipo_respuesta=base64`)
}

const getReporteVentasDetalladasPorUsuario = async ({ sedeId, desde, hasta, usuarioId }) => {
  return await axiosIns.get(`reporte-ventas/ventas-detalladas-por-usuario?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}&usuario_id=${usuarioId}`)
}

const getReporteVentasDetalladasPorUsuarioPdf = async ({ sedeId, desde, hasta, usuarioId }) => {
  return await axiosIns.get(`reporte-ventas/ventas-detalladas-por-usuario-pdf?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}&usuario_id=${usuarioId}&tipo_respuesta=base64`)
}

const getReporteVentasDetalladasPorCliente = async ({ sedeId, desde, hasta, clienteProveedorId }) => {
  return await axiosIns.get(`reporte-ventas/ventas-detalladas-por-cliente?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}&cliente_proveedor_id=${clienteProveedorId}`)
}

const getReporteVentasDetalladasPorClientePdf = async ({ sedeId, desde, hasta, clienteProveedorId }) => {
  return await axiosIns.get(`reporte-ventas/ventas-detalladas-por-cliente-pdf?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}&cliente_proveedor_id=${clienteProveedorId}&tipo_respuesta=base64`)
}

const getReporteVentasTotalesMensuales = async ({ sedeId }) => {
  return await axiosIns.get(`reporte-ventas/ventas-totales-mensuales?sede_id=${sedeId}`)
}

const getReporteVentasTotalesMensualesPdf = async ({ sedeId }) => {
  return await axiosIns.get(`reporte-ventas/ventas-totales-mensuales-pdf?sede_id=${sedeId}&tipo_respuesta=base64`)
}

const getReporteVentasPorArea = async ({ sedeId, desde, hasta, }) => {
  return await axiosIns.get(`reporte-ventas/ventas-area-ubicacion?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}`)
}

const getReporteVentasPorAreaPdf = async ({ sedeId, desde, hasta, }) => {
  return await axiosIns.get(`reporte-ventas/ventas-area-ubicacion-pdf?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}&tipo_respuesta=base64`)
}

export default {
  getReporteVentasGenerales,
  getReporteVentasGeneralesPdf,
  getReporteVentasDetalladas,
  getReporteVentasDetalladasPdf,
  getReporteVentasDetalladasPorUsuario,
  getReporteVentasDetalladasPorUsuarioPdf,
  getReporteVentasDetalladasPorCliente,
  getReporteVentasDetalladasPorClientePdf,
  getReporteVentasTotalesMensuales,
  getReporteVentasTotalesMensualesPdf,
  getReporteVentasPorArea,
  getReporteVentasPorAreaPdf,
}
