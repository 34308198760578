<template>
  <ReporteTransaccionesTotalesMensuales
    titulo="Reportes - Ventas Totales Mensuales"
    :servicioGetReporte="servicioGetReporte"
    :servicioGetReportePdf="servicioGetReportePdf"
    tipoTransaccion="venta"
  />
</template>

<script>
import ReporteTransaccionesTotalesMensuales from "../../components/ReporteTransaccionesTotalesMensuales.vue";
import ReporteVentasService from "../services";

export default {
  name: "ReporteVentasTotalesMensualesLayout",
  components: {
    ReporteTransaccionesTotalesMensuales,
  },
  data() {
    return {};
  },
  methods: {
    servicioGetReporte: ReporteVentasService.getReporteVentasTotalesMensuales,
    servicioGetReportePdf:
      ReporteVentasService.getReporteVentasTotalesMensualesPdf,
  },
};
</script>